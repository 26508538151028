import React from "react"
import cx from "classnames"
import styles from "./icon-button.module.css"

export const IconButton = ({ children, disabled, fullWidth, ...props }) => {
  return (
    <button
      {...props}
      type="button"
      className={cx(styles.button, {
        [styles.disabled]: disabled,
        [styles.fullWidth]: fullWidth,
      })}
    >
      {children}
    </button>
  )
}
